
@font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
    font-weight: 400 500 600 700;
    font-style: normal;
}



/* @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
} */

